@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles for markdown content */
.markdown-content h1 {
  @apply text-2xl font-bold mt-4 mb-2;
}

.markdown-content h2 {
  @apply text-xl font-bold mt-3 mb-2;
}

.markdown-content h3 {
  @apply text-lg font-bold mt-3 mb-2;
}

.markdown-content p {
  @apply mb-2;
}

.markdown-content ul, .markdown-content ol {
  @apply ml-6 mb-2;
}

.markdown-content li {
  @apply mb-1;
}

.markdown-content code {
  @apply bg-gray-100 dark:bg-gray-700 px-1 py-0.5 rounded font-mono text-sm;
}

.markdown-content pre {
  @apply bg-gray-100 dark:bg-gray-700 p-4 rounded-md overflow-x-auto mb-2;
}

.markdown-content a {
  @apply text-blue-500 underline;
}

.markdown-content blockquote {
  @apply border-l-4 border-gray-300 dark:border-gray-600 pl-4 italic;
}

.markdown-content table {
  @apply border-collapse mb-2;
}

.markdown-content th, .markdown-content td {
  @apply border border-gray-300 dark:border-gray-600 px-2 py-1;
}

.markdown-content img {
  @apply max-w-full h-auto;
}