@keyframes scanline {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  .scanning-effect {
    position: relative;
    overflow: hidden;
  }
  
  .scanning-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(to bottom, rgba(64, 224, 208, 0) 0%, rgba(64, 224, 208, 0.3) 50%, rgba(64, 224, 208, 0) 100%),
      repeating-linear-gradient(0deg, transparent, transparent 2px, rgba(64, 224, 208, 0.1) 3px, rgba(64, 224, 208, 0.1) 3px);
    background-size: 100% 20px, 100% 4px;
    animation: scanline 2s linear infinite;
    pointer-events: none;
  }
  
  .scanning-effect::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(64, 224, 208, 0.1) 0%, rgba(64, 224, 208, 0) 70%);
    opacity: 0;
    animation: pulse 2s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }